import React from "react"
import styled from "styled-components"

const FooterDiv = styled.footer`
  width: 100%;
  height: 200px;
`
export default function Footer() {
  return <FooterDiv></FooterDiv>
}
