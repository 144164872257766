import React from "react"
import styled from "styled-components"
import Footer from "./Footer"

const Container = styled.main`
  margin: 3rem auto;
  width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 100%;
  }

  /* @media (max-width: 768px) {
    margin: 1.5rem auto;
    max-width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media (max-width: 767px) {
    width: 100%;
  } */
`

export default function Layout({ children }) {
  return (
    <Container>
      {children}
      <Footer />
    </Container>
  )
}
