// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-giftcertificate-js": () => import("./../../../src/pages/giftcertificate.js" /* webpackChunkName: "component---src-pages-giftcertificate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pitstop-index-js": () => import("./../../../src/pages/pitstop/index.js" /* webpackChunkName: "component---src-pages-pitstop-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-thestar-index-js": () => import("./../../../src/pages/thestar/index.js" /* webpackChunkName: "component---src-pages-thestar-index-js" */),
  "component---src-pages-thestar-preview-js": () => import("./../../../src/pages/thestar/preview.js" /* webpackChunkName: "component---src-pages-thestar-preview-js" */),
  "component---src-pages-thestar-receivedgift-js": () => import("./../../../src/pages/thestar/receivedgift.js" /* webpackChunkName: "component---src-pages-thestar-receivedgift-js" */)
}

